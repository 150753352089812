import React from "react"

export default function Home() {
  return (
    <div>
      <header>
        <h1 className="site-title">
          <a href="/">The Dangron Hob-blog</a>
        </h1>
        <small>
          In the Grim Darkness of the 21st century there isn't really anything
          to do.
        </small>
      </header>

      <main>

        <p>Regulars</p>
        <ul>
          <li>Warhammer 40,000 Imperium</li>
        </ul>

        <p>Upcoming Projects</p>
        <ul>
          <li>Creations of Bile</li>
          <li>Necrons army showcase</li>
          <li>Space marines army showcase</li>
        </ul>

        <p>Old Posts</p>
        <ol reversed>
          <li>
            <p>
              <a href="/cheap-black-legion-army">Cheap Black Legion Army</a>
            </p>
          </li>
          <li>
            <p>
              <a href="/cheap-ultramarines-army">Cheap Ultramarines Army</a>
            </p>
          </li>
        </ol>
      </main>
    </div>
  )
}
